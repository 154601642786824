// React Imports
import React, { useState } from 'react';

// Third-party Import
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useDispatch } from 'react-redux';

// Croner imports
import { participationSelection } from '../../actions/participation';

const options = [
  {
    id: 100000003,
    name: 'Yes',
  },
  {
    id: 100000002,
    name: 'Maybe',
  },
  {
    id: 100000001,
    name: 'No',
  },
];

const ParticipateSurveysContentOptions = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const dispatch = useDispatch();

  const handleSelect = (name, id, survey) => {
    setSelectedValue(name);
    if (survey) {
      dispatch(
        participationSelection({
          name: name,
          response_id: id,
          survey_guid: survey.guid,
          survey_name: survey.name,
          survey_price: survey.price,
        })
      );
    }
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} {...props}>
      <DropdownToggle
        style={{ width: '100%' }}
        caret={selectedValue ? false : true}
      >
        {selectedValue ? selectedValue : 'Selection:'}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => handleSelect('', null, props.survey)}>
          -
        </DropdownItem>
        {options.map((option, index) => (
          <DropdownItem
            key={index}
            onClick={() => handleSelect(option.name, option.id, props.survey)}
          >
            {option.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ParticipateSurveysContentOptions;
