import { AUTH_ERROR, AUTH_SUCCESS } from '../actions/authentication';
import { DOC_DOWNLOAD_ERROR } from '../actions/documents';
import { DOC_UPLOAD_ERROR } from '../actions/documents';
import { RESULTS_DOWNLOAD_ERROR } from '../actions/results';
import { CLEAR_MESSAGES } from '../actions/messages';
import { MESSAGE_SUBMITTED } from '../actions/contact';
import {
  PARTICIPATION_SUBMITTED,
  DUPLICATE_SUBMITTED,
} from '../actions/participation';
import { INFO_SUCCESS, INFO_ERROR } from '../actions/accountSettings';

export default function (state = {}, action) {
  switch (action.type) {
    case AUTH_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        successes: action.payload,
      };
    case CLEAR_MESSAGES:
      return {
        ...state,
        errors: action.payload,
        successes: action.payload,
      };
    case DOC_DOWNLOAD_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    case DOC_UPLOAD_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    case RESULTS_DOWNLOAD_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    case MESSAGE_SUBMITTED:
      return {
        ...state,
        successes: action.payload,
      };
    case PARTICIPATION_SUBMITTED:
      return {
        ...state,
        successes: action.payload,
      };
    case DUPLICATE_SUBMITTED:
      return {
        ...state,
        errors: action.payload,
      };
    case INFO_SUCCESS:
      return {
        ...state,
        successes: action.payload,
      };
    case INFO_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
  // return state;
}
