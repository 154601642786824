// React Imports
import React, { useEffect, useState } from 'react';

// Third-party Import
import { connect, useDispatch } from 'react-redux';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

// Croner imports:
import { participationInformation } from '../../actions/participation';

const ParticipateInformation = () => {
  const initialState = {
    first_name: '',
    last_name: '',
    job_title: '',
    pronouns: '',
    email: '',
    phone: '',
    company_name: '',
    address_one: '',
    address_two: '',
    address_three: '',
    city: '',
    country: '',
    state_province: '',
    comments: '',
  };
  const [state, setState] = useState(initialState);
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(participationInformation(state));
  }, [dispatch, state]);

  useEffect(() => {
    setState(prevState => ({ ...prevState, country: country, state_province: region }));
  }, [country, region]);

  return (
    <div id='participation-content'>
      <div id='participation-form'>
        <Row>
          <Col xl='6'>
            <FormGroup>
              <Input
                id='first_name'
                name='first_name'
                placeholder='First Name'
                type='text'
                value={state.first_name}
                onChange={(e) =>
                  setState({ ...state, first_name: e.target.value })
                }
                required
              />
              <div className='invalid-feedback'>First Name is required</div>
            </FormGroup>
          </Col>
          <Col xl='6'>
            <FormGroup>
              <Input
                id='last_name'
                name='last_name'
                placeholder='Last Name'
                type='text'
                value={state.last_name}
                onChange={(e) =>
                  setState({ ...state, last_name: e.target.value })
                }
                required
              />
              <div className='invalid-feedback'>Last Name is required</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl='6'>
            <FormGroup>
              <Input
                name='title'
                placeholder='Position Title'
                type='text'
                value={state.job_title}
                onChange={(e) =>
                  setState({ ...state, job_title: e.target.value })
                }
                required
              />
              <div className='invalid-feedback'>Position Title is required</div>
            </FormGroup>
          </Col>
          <Col xl='6'>
            <FormGroup>
              <Input
                name='pronouns'
                type='select'
                value={state.pronouns}
                onChange={(e) =>
                  setState({ ...state, pronouns: e.target.value })
                }
              >
                <option>Pronouns...</option>
                <option>He / Him / His</option>
                <option>She / Her / Hers</option>
                <option>Sie / Hir / Hirs</option>
                <option>They / Them / Theirs</option>
                <option>Xe / Xem / Xyrs</option>
                <option>Ze / Zir / Zirs</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl='6'>
            <FormGroup>
              <Input
                id='email'
                name='email'
                placeholder='Email'
                type='email'
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                required
              />
              <div className='invalid-feedback'>Email is required</div>
            </FormGroup>
          </Col>
          <Col xl='6'>
            <FormGroup>
              <Input
                id='phone'
                name='phone'
                placeholder='Phone'
                type='tel'
                value={state.phone}
                onChange={(e) => setState({ ...state, phone: e.target.value })}
                required
              />
              <div className='invalid-feedback'>Phone is required</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl='12'>
            <FormGroup>
              <Input
                id='company'
                name='company'
                placeholder='Company Name'
                type='text'
                value={state.company_name}
                onChange={(e) =>
                  setState({ ...state, company_name: e.target.value })
                }
                required
              />
              <div className='invalid-feedback'>Company is required</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl='12'>
            <FormGroup>
              <Input
                id='address_one'
                name='address_one'
                placeholder='Address 1'
                type='text'
                value={state.address_one}
                onChange={(e) =>
                  setState({ ...state, address_one: e.target.value })
                }
                required
              />
              <div className='invalid-feedback'>Address 1 is required</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl='12'>
            <FormGroup>
              <Input
                id='address_two'
                name='address_two'
                placeholder='Address 2'
                type='text'
                value={state.address_two}
                onChange={(e) =>
                  setState({ ...state, address_two: e.target.value })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl='12'>
            <FormGroup>
              <Input
                id='address_three'
                name='address_three'
                placeholder='Address 3'
                type='text'
                value={state.address_three}
                onChange={(e) =>
                  setState({ ...state, address_three: e.target.value })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl='12'>
            <FormGroup>
              <Input
                id='city'
                name='city'
                placeholder='City'
                type='text'
                value={state.city}
                onChange={(e) => setState({ ...state, city: e.target.value })}
                required
              />
              <div className='invalid-feedback'>City is required</div>
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ margin: '0 15px 15px' }}>
          <Col style={{ paddingLeft: '0' }} xl='6'>
            <CountryDropdown
              value={country}
              onChange={(val) => setCountry(val)}
              classes='form-control'
              required
            />
            <div className='invalid-feedback'>Country is required</div>
          </Col>
          <Col xl='6' style={{ paddingRight: '0' }}>
            <RegionDropdown
              country={country}
              value={region}
              onChange={(val) => setRegion(val)}
              classes='form-control'
              required
            />
            <div className='invalid-feedback'>Region is required</div>
          </Col>
        </Row>
        <Row>
          <Col lg='12'>
            <Input
              name='comments'
              type='textarea'
              rows='6'
              placeholder='Message...'
              value={state.comments}
              onChange={(e) => setState({ ...state, comments: e.target.value })}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  participationInformation,
};

export default connect(null, mapDispatchToProps)(ParticipateInformation);
