import './Navbar.scss';

import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';

import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavLink,
  NavItem,
  NavbarToggler,
} from 'reactstrap';

import Messages from '../Messages';

import logo from './../croner_logo.png';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };

    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  aboutMenu() {
    return (
      <div className='custom-dropdown-menu'>
        <NavItem>
          <NavLink
            style={{ width: '120px' }}
            href='https://www.croner.com/about'
          >
            About
          </NavLink>
          <div className='nav-dropdown-menu'>
            <ul>
              <a href='https://www.croner.com/team'>
                <li>OUR TEAM</li>
              </a>
            </ul>
          </div>
        </NavItem>
      </div>
    );
  }

  accountMenu() {
    return (
      <div className='custom-dropdown-menu'>
        <NavItem>
          <NavLink href='/account'>Account</NavLink>
          <div className='nav-dropdown-menu'>
            <ul>
              <Link
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
                to='/account#general_info'
              >
                <li>General Information</li>
              </Link>
              <Link
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
                to='/account#update_password'
              >
                <li>Update Password</li>
              </Link>
              <Link
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
                to='/account#update_sq'
              >
                <li>Update Security Questions</li>
              </Link>
              {this.props.productBuyer &&
              this.props.features &&
              this.props.features.find((obj) => obj.id === 1 && obj.render) ? (
                <Link
                  scroll={(el) =>
                    el.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                  to='/account#org_contacts'
                >
                  <li>Organization Contacts</li>
                </Link>
              ) : null}

              <Link
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
                to='/account#active_sessions'
              >
                <li>My Active Sessions</li>
              </Link>
            </ul>
          </div>
        </NavItem>
      </div>
    );
  }

  boardAdvisoryMenu() {
    return (
      <div className='custom-dropdown-menu'>
        <NavItem>
          <NavLink
            style={{ width: '170px' }}
            href='https://www.croner.com/board-advisory-services'
          >
            Board Advisory
          </NavLink>
          <div className='nav-dropdown-menu'>
            <ul>
              <a href='https://www.croner.com/board-advisory-services#_compensation-design-and-monitoring'>
                <li>COMPENSATION DESIGN AND MONITORING</li>
              </a>
              <a href='https://www.croner.com/board-advisory-services#_compensation-committee-support'>
                <li>COMPENSATION COMMITTEE SUPPORT</li>
              </a>
              <a href='https://www.croner.com/board-advisory-services#_board-advisory-laws-and-guidelines'>
                <li>BOARD ADVISORY LAWS AND GUIDELINES</li>
              </a>
            </ul>
          </div>
        </NavItem>
      </div>
    );
  }

  careersMenu() {
    return (
      <NavItem style={{ marginRight: '0' }}>
        <NavLink
          style={{ width: '110px' }}
          href='https://www.croner.com/careers'
        >
          Careers
        </NavLink>
      </NavItem>
    );
  }

  compensationSurveys() {
    return (
      <div className='custom-dropdown-menu'>
        <NavItem>
          <NavLink
            style={{ width: '170px' }}
            href='https://www.croner.com/compensation-surveys'
          >
            Compensation Surveys
          </NavLink>
          <div className='nav-dropdown-menu'>
            <ul>
              <a href='https://www.croner.com/compensation-surveys#_why-do-you-need-survey-data'>
                <li>WHY DO YOU NEED COMPENSATION SURVEY DATA?</li>
              </a>
              <a href='https://www.croner.com/croner-digital-content-technology-survey'>
                <li>DIGITAL CONTENT & TECHNOLOGY</li>
              </a>
              <a
                className='sub-dropdown-menu-parent'
                href='https://www.croner.com/croner-software-games-survey'
              >
                <li>SOFTWARE GAMES &gt;&gt;</li>
              </a>
              <div style={{ top: '120px' }} className='sub-dropdown-menu'>
                <ul style={{ paddingTop: 0 }}>
                  <a href='https://www.croner.com/croner-software-games-survey'>
                    <li>SOFTWARE GAMES - NORTH AMERICA</li>
                  </a>
                  <a href='https://www.croner.com/croner-software-games-survey-international'>
                    <li>SOFTWARE GAMES - INTERNATIONAL</li>
                  </a>
                </ul>
              </div>
              <a href='https://www.croner.com/croner-animation-and-visual-effects-survey'>
                <li>ANIMATION AND VISUAL EFFECTS</li>
              </a>
              <a href='https://www.croner.com/croner-local-media-sales-survey'>
                <li>LOCAL MEDIA</li>
              </a>
              <a
                className='sub-dropdown-menu-parent'
                href='https://www.croner.com/croner-entertainment-survey'
              >
                <li>ENTERTAINMENT &gt;&gt;</li>
              </a>
              <div style={{ top: '232px' }} className='sub-dropdown-menu'>
                <ul style={{ paddingTop: 0 }}>
                  <a href='https://www.croner.com/croner-entertainment-survey'>
                    <li>ENTERTAINMENT - NORTH AMERICA</li>
                  </a>
                  <a href='https://www.croner.com/croner-entertainment-survey-international'>
                    <li>ENTERTAINMENT - INTERNATIONAL</li>
                  </a>
                </ul>
              </div>
              <a href='https://www.croner.com/total-compensation-survey-of-foundations'>
                <li>GRANTMAKING FOUNDATIONS</li>
              </a>
              <a href='https://www.croner.com/c2hr-content-developers-compensation-survey'>
                <li>C2HR CONTENT DEVELOPERS</li>
              </a>
              <a href='https://www.croner.com/c2hr-connectivity-providers-compensation-survey'>
                <li>C2HR CONNECTIVITY PROVIDERS</li>
              </a>
              <a href='https://www.croner.com/compensation-surveys#custom-surveys'>
                <li>CUSTOM COMPENSATION SURVEYS</li>
              </a>
            </ul>
          </div>
        </NavItem>
      </div>
    );
  }

  consultingServicesMenu() {
    return (
      <div className='custom-dropdown-menu'>
        <NavItem>
          <NavLink
            style={{ width: '170px' }}
            href='https://www.croner.com/compensation-consulting'
          >
            Compensation &nbsp;&nbsp;Consulting
          </NavLink>
          <div className='nav-dropdown-menu'>
            <ul>
              <a href='https://www.croner.com/nonprofit-consulting'>
                <li>PHILANTHROPIC AND NONPROFIT CONSULTING</li>
              </a>
              <a href='https://www.croner.com/compensation-consulting#_total-compensation-design'>
                <li>TOTAL COMPENSATION DESIGN</li>
              </a>
              <a href='https://www.croner.com/compensation-consulting#_short-term-incentive-plans'>
                <li>SHORT-TERM INCENTIVE PLANS</li>
              </a>{' '}
              <a href='https://www.croner.com/compensation-consulting#_equity-plans'>
                <li>EQUITY PLANS</li>
              </a>
              <a href='https://www.croner.com/compensation-consulting#_sales-incentive-plans'>
                <li>SALES INCENTIVE PLANS</li>
              </a>
              <a href='https://www.croner.com/compensation-consulting#_executive-compensation-plans'>
                <li>EXECUTIVE COMPENSATION PLANS</li>
              </a>
              <a href='https://www.croner.com/compensation-consulting#_pay-equity-analysis'>
                <li>PAY EQUITY ANALYSIS</li>
              </a>
              <a href='https://www.croner.com/compensation-consulting#_geographic-pay-differentials'>
                <li>GEOGRAPHIC PAY DIFFERENTIALS</li>
              </a>
              <a href='https://www.croner.com/compensation-surveys#_custom-surveys'>
                <li>CUSTOM SURVEYS AND STUDIES</li>
              </a>
            </ul>
          </div>
        </NavItem>
      </div>
    );
  }

  contactFormMenu() {
    return (
      <div className='custom-dropdown-menu'>
        <NavItem>
          <NavLink href='/contact'>Contact</NavLink>
          <div className='nav-dropdown-menu'>
            <ul>
              <Link
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
                to='/contact#contact_form'
              >
                <li>Contact Form</li>
              </Link>
              <Link
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
                to='/contact#faq'
              >
                <li>FAQ</li>
              </Link>
            </ul>
          </div>
        </NavItem>
      </div>
    );
  }

  contactMenu() {
    return (
      <NavItem>
        <NavLink
          // style={{ paddingRight: '0' }}
          href='https://www.croner.com/contact'
        >
          Contact
        </NavLink>
      </NavItem>
    );
  }

  homeMenu() {
    return (
      <div className='custom-dropdown-menu'>
        <NavItem>
          <NavLink href='/home'>Home</NavLink>
        </NavItem>
      </div>
    );
  }

  newsTrendsMenu() {
    return (
      <NavItem style={{ marginRight: '0' }}>
        <NavLink
          style={{ paddingRight: '0' }}
          href='https://www.croner.com/news'
        >
          News & Trends
        </NavLink>
      </NavItem>
    );
  }

  participationMenu() {
    return this.props.features &&
      this.props.features.find((obj) => obj.id === 2 && obj.render) ? (
      <div className='custom-dropdown-menu' style={{ paddingRight: '0' }}>
        <NavItem>
          <NavLink style={{ cursor: 'default' }}>Participate</NavLink>
          <div
            className='nav-dropdown-menu'
            style={
              this.props.isAuthenticated === false
                ? { right: '0' }
                : { left: '0' }
            }
          >
            <ul>
              <Link to='/participate/surveys'>
                <li>Croner and C2HR Surveys</li>
              </Link>
              <Link to='/participate/foundations'>
                <li>Croner Foundation Survey</li>
              </Link>
            </ul>
          </div>
        </NavItem>
      </div>
    ) : null;
  }

  signOut() {
    return (
      <div className='custom-dropdown-menu'>
        <NavItem>
          <NavLink style={{ paddingRight: '0' }} href='/auth/signout/'>
            Sign Out
          </NavLink>
        </NavItem>
      </div>
    );
  }

  surveyMaterials() {
    return (
      <div className='custom-dropdown-menu'>
        <NavItem>
          <NavLink href='/documents'>Survey Materials</NavLink>
          <div className='nav-dropdown-menu'>
            <ul>
              <Link
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
                to='/documents#planning_meeting'
              >
                <li>Planning Meeting</li>
              </Link>
              <Link
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
                to='/documents#questionnaire'
              >
                <li>Questionnaire</li>
              </Link>
              <Link
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
                to='/documents#submit_documents'
              >
                <li>Submit Documents</li>
              </Link>
              <Link
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
                to='/documents#survey_resources'
              >
                <li>Survey Resources</li>
              </Link>
            </ul>
          </div>
        </NavItem>
      </div>
    );
  }

  surveyResults() {
    return (
      <div className='custom-dropdown-menu'>
        <NavItem>
          <NavLink href='/results'>Survey Results</NavLink>
          <div className='nav-dropdown-menu'>
            <ul>
              <Link
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
                to='/results#results_documents'
              >
                <li>Results Documents</li>
              </Link>
              <Link
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
                to='/results#query'
              >
                <li>Query</li>
              </Link>
            </ul>
          </div>
        </NavItem>
      </div>
    );
  }

  render() {
    // console.log(this.props.isAuthenticated)
    return (
      <div id='navigation'>
        <div id='top-bar' />
        <Navbar expand='xl' light>
          <NavbarBrand
            href='https://croner.com'
            target='_self'
            style={{ padding: '0 4px' }}
          >
            <img src={logo} alt='The Croner Company Logo' />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} />
          <Collapse isOpen={this.state.collapsed} navbar>
            <Nav className='ml-auto' navbar>
              {this.props.isAuthenticated === false ? (
                <>
                  {this.aboutMenu()}
                  {this.consultingServicesMenu()}
                  {this.boardAdvisoryMenu()}
                  {this.compensationSurveys()}
                  {this.newsTrendsMenu()}
                  {this.careersMenu()}
                  {this.contactMenu()}
                  {this.participationMenu()}
                </>
              ) : (
                <>
                  {this.homeMenu()}
                  {this.participationMenu()}
                  {this.surveyMaterials()}
                  {this.surveyResults()}
                  {this.contactFormMenu()}
                  {this.props.companyGuid !==
                  '3b9f4fdd-e6df-ee11-904c-6045bd0a1358'
                    ? this.accountMenu()
                    : undefined}
                  {this.consultingServicesMenu()}
                  {this.signOut()}
                </>
              )}
            </Nav>
          </Collapse>
        </Navbar>
        <Messages />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    id: state.home.id,
    isAuthenticated: state.auth.authenticated,
    features: state.auth.features,
    productBuyer: state.auth.productBuyer,
    companyGuid: state.auth.companyGuid,
  };
}

export default connect(mapStateToProps, {})(Navigation);
