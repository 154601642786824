import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

// Render on every route
import Navigation from './Global/Navbar';
import Footer from './Global/Footer';
import Cookies from './Global/Cookies';

// Various auth routes
import SecurityQuestions from './Authentication/SecurityQuestions';
import SetPassword from './Authentication/SetPassword';
import SetupUser from './Authentication/SetupUser';
import ExpiredKey from './Authentication/ExpiredKey';

// Route specific
import Authentication from './Authentication';
import Home from './Home';
import Participate from './Participate';
import Documents from './Documents';
import Results from './Results';
import Contact from './Contact';
import Account from './Account';
import ErrorPage from './ErrorPage';
import Products from './Global/Products/Products';
import QueryDemo from './Demo/QueryDemo';

// Session
import { getSession } from '../actions/authentication';

// HoC to wrap protected routes in
import Timers from '../services/timers';
import RequireAuth from '../services/requireAuth';

// Store
import configureStore, { history } from '../services/history';

const AppContent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSession());
  }, [dispatch]);

  const protectedRoute = compose(Timers, RequireAuth);

  return (
    <>
      <Navigation />
      <div style={{ marginTop: '99px' }}>
        <Switch>
          <Route exact path='/home*' component={protectedRoute(Home)} />
          <Route
            exact
            path='/participate/*'
            component={Participate}
          />
          <Route
            exact
            path='/documents*'
            component={protectedRoute(Documents)}
          />
          <Route exact path='/results/' component={protectedRoute(Results)} />
          <Route exact path='/contact/' component={protectedRoute(Contact)} />
          <Route exact path='/account/' component={protectedRoute(Account)} />
          <Route exact path='/demo/query/' component={QueryDemo} />
          <Route
            exact
            path='/auth/security_questions/f=:f&i=:id&k=:key/'
            component={SecurityQuestions}
          />
          <Route
            exact
            path='/auth/set_password/f=:f&i=:id&k=:key/'
            component={SetPassword}
          />
          <Route
            // exact
            path='/auth/setup_user/f=:f&i=:id&k=:key/'
            component={SetupUser}
          />
          <Route
            exact
            path='/auth/expired_key/f=:f&i=:id&k=:key/'
            component={ExpiredKey}
          />

          <Route exact path='/auth/*' component={Authentication} />
          <Route exact path='/' component={Authentication} />
          <Route component={ErrorPage} />
        </Switch>
      </div>
      <Products />
      <Cookies />
      <Footer />
    </>
  );
};

const App = () => {
  const store = configureStore();

  // routes
  return (
    <div data-testid='App'>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AppContent />
        </ConnectedRouter>
      </Provider>
    </div>
  );
};

export default App;
