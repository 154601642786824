// Styles for this component
import './Participate.scss';

// React Imports
import React from 'react';

// Third-party Imports
import { connect } from 'react-redux';

// Croner Imports
import ParticipateFoundations from './ParticipateFoundations';
import ParticipateSurveys from './ParticipateSurveys';
import ParticipateConfirmation from './ParticipateConfirmation';

const Participate = ({ features }) => {
  const path = window.location.pathname;

  if (
    features &&
    Object.keys(features).length &&
    features.find((obj) => obj.id === 2 && obj.render)
  ) {
    return (
      <div id='participation'>
        <ParticipateConfirmation />
        {path === '/participate/surveys' ? (
          <ParticipateSurveys />
        ) : path === '/participate/foundations' ? (
          <ParticipateFoundations />
        ) : (
          <></>
        )}
      </div>
    );
  }
  return <div style={{ width: '100%', height: '100%' }}></div>;
};

const mapStateToProps = (state) => {
  return {
    features: state.auth.features,
  };
};

export default connect(mapStateToProps)(Participate);
