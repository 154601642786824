// React Imports
import React from 'react';

// Third-party Import
import { connect, useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardTitle,
  CardSubtitle,
  CardText,
  Col,
  Row,
} from 'reactstrap';

// Croner Imports
import {
  participationConfirmationToggle,
  participationSelection,
} from '../../actions/participation';
import ParticipateHeader from './ParticipateHeader';

const ParticipateFoundations = () => {
  const dispatch = useDispatch();

  const handleClick = (name, id, price) => {
    dispatch(participationConfirmationToggle(true));
    dispatch(
      participationSelection({
        name: name,
        response_id: id,
        survey_guid: 'a6784176-0779-ee11-8178-6045bd086313',
        survey_name: 'Croner Compensation Survey of Foundations',
        survey_price: price,
      })
    );
  };

  return (
    <>
      <ParticipateHeader
        title={
          <h1>
            2025 <em>Croner Compensation Survey of Foundations</em>
          </h1>
        }
      />
      <div id='participation-content'>
        <Card>
          <Row>
            <Col xl='12'>
              <p>
                Now in its 28th year, the{' '}
                <em>Croner Compensation Survey of Foundations</em> is the most
                comprehensive compensation and benefits resource for grantmaking
                and community foundations covering demographics, salary
                administration, cash incentives / bonuses, benefits, Board
                Compensation, and related information.
              </p>
              <h5>SURVEY PARTICIPATION LEVELS</h5>
              <p>
                Three participation levels are offered for the 2025{' '}
                <em>Croner Compensation Survey of Foundations</em>:{' '}
                <b>Network, Partner and Partner Plus</b>.
              </p>
              <h5>PARTICIPATION LEVELS AT-A-GLANCE</h5>
              <table id='participation-tiers'>
                <thead>
                  <tr>
                    <th></th>
                    <th colSpan='3'>PARTICIPATION LEVEL</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th className='participation-col-2'>PARTNER PLUS</th>
                    <th className='participation-col-3'>PARTNER</th>
                    <th className='participation-col-4'>NETWORK</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className='participation-col-1'>
                      Survey Participation Fee
                    </th>
                    <td className='participation-col-2'>$9,000</td>
                    <td className='participation-col-3'>$8,000</td>
                    <td className='participation-col-4'>$2,500</td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>
                      Custom Cut of Select Peer Group
                    </th>
                    <td className='participation-col-2'>Included</td>
                    <td className='participation-col-3'>$1,500</td>
                    <td className='participation-col-4'></td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>
                      Planning Meeting with Roundtable
                    </th>
                    <td className='participation-col-2'>X</td>
                    <td className='participation-col-3'>X</td>
                    <td className='participation-col-4'></td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>Trends Presentation</th>
                    <td className='participation-col-2'>X</td>
                    <td className='participation-col-3'>X</td>
                    <td className='participation-col-4'></td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>
                      Report of Results (PDF)
                    </th>
                    <td className='participation-col-2'>X</td>
                    <td className='participation-col-3'>X</td>
                    <td className='participation-col-4'>X</td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>
                      Report of Results (Excel)
                    </th>
                    <td className='participation-col-2'>X</td>
                    <td className='participation-col-3'>X</td>
                    <td className='participation-col-4'></td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>
                      Report of Results Webinar
                    </th>
                    <td className='participation-col-2'>X</td>
                    <td className='participation-col-3'>X</td>
                    <td className='participation-col-4'></td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>
                      Annual Salary Budget Survey
                    </th>
                    <td className='participation-col-2'>X</td>
                    <td className='participation-col-3'>X</td>
                    <td className='participation-col-4'>X</td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>
                      Additional Custom Cut
                    </th>
                    <td className='participation-col-2'>$1,500</td>
                    <td className='participation-col-3'>$1,500</td>
                    <td className='participation-col-4'></td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xl='12'>
              <h5>REGISTER TODAY</h5>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: '10px',
              marginBottom: '20px',
              display: 'flex',
            }}
          >
            <Col xl='4'>
              <Card
                style={{
                  color: 'rgb(187, 145, 32)',
                  border: '1px solid rgb(187, 145, 32)',
                }}
              >
                <CardTitle>Partner Plus</CardTitle>
                <CardText>
                  We will participate at the Partner Plus level in the 2025 CSF
                  Survey – <strong>$9,000</strong>.
                </CardText>
                <CardSubtitle>
                  Partner Plus participants receive the Compensation Data
                  Results in Microsoft Excel and a PDF copy of the Report of
                  Results, and a custom cut report of selected peers, along with
                  invitations to the Survey Planning Meeting for Partners and
                  the Report of the Results Webinar.
                </CardSubtitle>
                <Button
                  style={{ background: 'rgb(187, 145, 32)' }}
                  onClick={() => handleClick('Partner Plus', 799680006, 9000)}
                >
                  Participate as Partner Plus
                </Button>
              </Card>
            </Col>
            <Col xl='4'>
              <Card
                style={{
                  color: 'rgb(52, 95, 162)',
                  border: '1px solid rgb(52, 95, 162)',
                }}
              >
                <CardTitle>Partner</CardTitle>
                <CardText>
                  We will participate at the Partner level in the 2025 CSF
                  Survey – <strong>$8,000</strong>.
                </CardText>
                <CardSubtitle>
                  Partners receive the Compensation Data Results in Microsoft
                  Excel and a PDF copy of the Report of Results, along with
                  invitations to the Survey Planning Meeting for Partners and
                  the Report of Results Webinar.
                </CardSubtitle>
                <Button
                  style={{ background: 'rgb(52, 95, 162)' }}
                  onClick={() => handleClick('Partner', 799680007, 8000)}
                >
                  Participate as Partner
                </Button>
              </Card>
            </Col>
            <Col xl='4'>
              <Card
                style={{
                  color: 'rgb(80, 116, 51)',
                  border: '1px solid rgb(80, 116, 51)',
                }}
              >
                <CardTitle>Network</CardTitle>
                <CardText>
                  We will participate at the Network level in the 2025 CSF
                  Survey – <strong>$2,500</strong>.
                </CardText>
                <CardSubtitle>
                  Network participants receive a PDF copy of the Report of
                  Results.
                </CardSubtitle>
                <Button
                  style={{ background: 'rgb(80, 116, 51)' }}
                  onClick={() => handleClick('Network', 799680008, 2500)}
                >
                  Participate as Network
                </Button>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>PARTNER AND PARTNER PLUS</h5>{' '}
              <p>
                The fee to participate in the Survey at the Partner level is
                $8,000 and at Partner Plus level is $9,000 (which includes one
                complimentary custom cut report).
              </p>
              <p>Participation at the Partner levels includes:</p>
              <ul>
                <li>
                  The full Report of Results in PDF and the Compensation Data
                  Results in Microsoft Excel with additional resource tabs.
                </li>
                <li>
                  The ability to order Custom Cut reports of selected peer
                  foundations in the Survey for $1,500.{' '}
                  <b>
                    Partner Plus participants receive one complimentary Custom
                    Cut report, a savings of $500.
                  </b>
                </li>
                <li>
                  Attendance at the annual Survey Planning Meeting for Partners.
                  The meeting agenda includes a Trends Presentation by CEO Hali
                  Croner, a Jobs Discussion, a Roundtable Session, and the
                  opportunity to network with colleagues in other foundations.
                </li>
                <li>
                  Invitation to attend the annual Report of Results Webinar with
                  presentation of Survey Highlights.
                </li>
                <li>
                  Invitations to special Croner Pulse Surveys and Roundtable
                  Sessions.
                </li>
                <li>
                  The <em>Croner Salary Budget Survey – August</em>{' '}
                  (complimentary).
                </li>
              </ul>
              <h5>NETWORK</h5>
              <p>
                The fee to participate in the Survey at the Network level is
                $2,500.
              </p>
              <p>Participation at the Network level includes:</p>
              <ul>
                <li>The full Report of Results in PDF.</li>
                <li>
                  An invitation to the{' '}
                  <em>Croner Salary Budget Survey – August</em> (complimentary).
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>KEY FEATURES OF THE SURVEY – All Participation Levels</h5>
              <ul>
                <li>
                  Data reported for all foundations and separately for private
                  and community foundations.
                </li>
                <li>
                  Croner Survey portal for secure submissions and results
                  delivery, a customized intake Questionnaire pre-populated with
                  your organization’s responses from the previous year's survey,
                  reports and materials, and data queries by position.
                </li>
                <li>
                  More foundation-specific positions covered than any other
                  survey of the sector, including 216 benchmark jobs, 29
                  Executive positions and 187 Non-Executive positions.
                </li>
                <li>
                  Comprehensive reporting of practices including demographics,
                  salary administration, cash incentive / bonuses, benefits
                  offered, Board of Directors compensation and related
                  information.
                </li>
                <li>
                  Proprietary methodology to quantify benefits costs for a more
                  realistic assessment of the foundation’s total compensation
                  investment.
                </li>
                <li>
                  Commitment to ongoing enhancements including regular updates
                  to survey benchmarks to reflect changes and complexities of
                  foundation jobs.
                </li>
              </ul>
              <p>
                Additional details can be found at{' '}
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.croner.com/total-compensation-survey-of-foundations'
                >
                  https://www.croner.com/total-compensation-survey-of-foundations
                </a>
                .
              </p>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  participationConfirmationToggle,
  participationSelection,
};

const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipateFoundations);
