// React Imports
import React from 'react';

// Croner Imports
import ParticipateSurveysContentOptions from './ParticipateSurveysContentOptions';

const ParticipateSurveysContent = (props) => {
  return props.suryves.map((survey, index) => {
    return (
      <tr key={survey.guid}>
        <td className='survey-description'>
          <em>
            <a href={survey.link} target='_blank' rel='noopener noreferrer'>
              {survey.name}
            </a>{' '}
            ("{survey.abbr}")
          </em>{' '}
          - {survey.description}
        </td>
        <td>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          }).format(survey.price)}
        </td>
        <td>{survey.date}</td>
        <td>
          <ParticipateSurveysContentOptions survey={survey} />
        </td>
      </tr>
    );
  });
};

export default ParticipateSurveysContent;
