// Third-party imports
import axios from 'axios';

// Establish the different types
export const DUPLICATE_SUBMITTED = 'duplicate_submitted';
export const PARTICIPATION_CONFIRMATION_TOGGLE =
  'participation_confirmation_toggle';
export const PARTICIPATION_INFORMATION = 'participation_information';
export const PARTICIPATION_SELECTION = 'participation_selection;';
export const PARTICIPATION_SUBMITTED = 'participation_submitted';
export const PARTICIPATION_SUBMITTED_SUCCESS =
  'participation_submitted_success';

export const participationConfirmationToggle = (toggle) => {
  return {
    type: PARTICIPATION_CONFIRMATION_TOGGLE,
    payload: toggle,
  };
};

export const participationInformation = (information) => {
  return {
    type: PARTICIPATION_INFORMATION,
    payload: information,
  };
};

export const participationSelection = (selection) => {
  return {
    type: PARTICIPATION_SELECTION,
    payload: selection,
  };
};

export const submitParticipation = (body, csrfToken) => {
  return function (dispatch) {
    axios
      .post(`/api/client/surveys/participation/`, body, {
        headers: {
          'content-type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
      })
      .then((response) => {
        dispatch({
          type: PARTICIPATION_SUBMITTED_SUCCESS,
          payload: true,
        });
        dispatch({
          type: PARTICIPATION_SUBMITTED,
          payload: { success: ['Participation submitted successfully.'] },
        });
        dispatch({
          type: PARTICIPATION_SUBMITTED_SUCCESS,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: DUPLICATE_SUBMITTED,
          payload: {
            error: ['You have already submitted a response.'],
          },
        });
      });
  };
};
