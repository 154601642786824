// React Imports
import React, { useEffect, useState } from 'react';

// Third-party Import
import { Button, Card, Col, Row } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';

// Croner imports
import ParticipateHeader from './ParticipateHeader';
import ParticipateSurveysContent from './ParticipateSurveysContent';
import { participationConfirmationToggle } from '../../actions/participation';

const surveyData = [
  {
    guid: '7473f003-0c96-ee11-be36-6045bd023afd',
    name: 'Croner Animation and Visual Effects Survey',
    abbr: 'AVE',
    link: 'https://www.croner.com/croner-animation-and-visual-effects-survey',
    description:
      ' for companies that produce animated feature films or develop animation and/or visual effects for feature films, television, software games, digital and streamed entertainment.',
    price: 13250,
    date: 'April',
  },
  {
    guid: '8873f003-0c96-ee11-be36-6045bd023afd',
    name: 'Croner Digital Content and Technology Survey',
    abbr: 'DCT',
    link: 'https://www.croner.com/croner-digital-content-technology-survey',
    description:
      'for companies that develop and publish frequently updated internet content and/or provide ecommerce transactions and services.',
    price: 13250,
    date: 'March',
  },
  {
    guid: '8e73f003-0c96-ee11-be36-6045bd023afd',
    name: 'Croner Entertainment Survey',
    abbr: 'ENT',
    link: 'https://www.croner.com/croner-entertainment-survey',
    description:
      'for companies that produce entertainment content distributed in theaters, theme parks and across television and digital networks. The survey also serves the music industry.  Positions included cover all organizational levels in functions such as production, postproduction, marketing, distribution, home entertainment, music, consumer product licensing, legal & business affairs and G&A.',
    price: 13250,
    date: 'June',
  },
  {
    guid: '9573f003-0c96-ee11-be36-6045bd023afd',
    name: 'Croner Entertainment Survey - International',
    abbr: 'ENTI',
    link: 'https://www.croner.com/croner-entertainment-survey-international',
    description:
      'for large film studios, smaller film studios and companies with international locations and employees that produce entertainment content distributed in theaters or across television and digital networks in locations in the United Kingdom, Europe and Canada. Other international areas may be reported if sufficient data is submitted.',
    price: 7500,
    date: 'June',
  },
  {
    guid: '9b73f003-0c96-ee11-be36-6045bd023afd',
    name: 'Croner Local Media Survey',
    abbr: 'LMS',
    link: 'https://www.croner.com/croner-local-media-sales-survey',
    description:
      'for television stations, radio stations, MSOs, digital organizations, publishers and other media companies.  Job families include advertising sales, sales programming and operations positions.',
    price: 13250,
    date: 'May',
  },
  {
    guid: 'a773f003-0c96-ee11-be36-6045bd023afd',
    name: 'Croner Software Games Survey - North America',
    abbr: 'SWG',
    link: 'https://www.croner.com/croner-software-games-survey',
    description:
      'for software companies in the U.S. and Canada creating, developing and/or publishing video games in all gaming platform types including mobile, social, console, PC, cloud, handheld, MMO, virtual reality and casino games.',
    price: 13750,
    date: 'July',
  },
  {
    guid: 'a173f003-0c96-ee11-be36-6045bd023afd',
    name: 'Croner Software Games Survey - International',
    abbr: 'SGI',
    link: 'https://www.croner.com/croner-software-games-survey-international',
    description:
      'for software companies with international locations and employees creating, developing and/or publishing video games in all gaming platform types including mobile, social, console, PC, handheld, MMO, cloud gaming and virtual reality.',
    price: 7500,
    date: 'July',
  },
  {
    guid: '7a73f003-0c96-ee11-be36-6045bd023afd',
    name: 'C2HR Content Developers Compensation Survey',
    abbr: 'CDS',
    link: 'https://www.croner.com/croner-software-games-survey-international',
    description:
      'for cable programmers, broadcast networks, digital media and technology companies, on demand and internet streaming content providers or other content companies with related programming positions.',
    price: 12200,
    date: 'March',
  },
  {
    guid: '8073f003-0c96-ee11-be36-6045bd023afd',
    name: 'C2HR Connectivity Providers Compensation Survey',
    abbr: 'CPS',
    link: 'https://www.croner.com/croner-software-games-survey-international',
    description:
      'for cable multiple systems operators (MSOs), satellite and telecommunications operators and home security companies.',
    price: 12200,
    date: 'March',
  },
];

// Croner Imports
const ParticipateSurveys = ({ selection }) => {
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(participationConfirmationToggle(true));
  };
  useEffect(() => {
    if (selection.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selection]);

  return (
    <>
      <ParticipateHeader
        title={
          <h1>
            2025 <em>Croner Compensation Surveys</em>
          </h1>
        }
      />
      <div id='participation-content'>
        <Card>
          <Row>
            <Col xl='12'>
              <table id='participation-surveys'>
                <thead>
                  <tr>
                    <th>2025 Surveys conducted by The Croner Company</th>
                    <th>2025 Survey Participation Fee (USD)*</th>
                    <th>2025 Survey Launch Date</th>
                    <th>2025 Commitment Plans</th>
                  </tr>
                </thead>
                <tbody>
                  <ParticipateSurveysContent suryves={surveyData} />
                </tbody>
              </table>
            </Col>
          </Row>
          <Row style={{ padding: '0 15px 15px' }}>
            <Button
              color='primary'
              size='lg'
              block
              onClick={() => handleClick()}
              disabled={disabled}
            >
              Confirm Selection
            </Button>
          </Row>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selection: state.participation.selections,
  };
};

export default connect(mapStateToProps)(ParticipateSurveys);
