// React Imports
import React from 'react';

// Third-party Imports

// Croner Imports

const ParticipationHeader = ({ title }) => {
  return (
    <div id='title'>
      {title}
    </div>
  );
};

export default ParticipationHeader;
