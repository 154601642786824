// React Imports
import React, { useCallback, useEffect, useState } from 'react';

// Third-party Import
import { connect, useDispatch } from 'react-redux';
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

// Croner imports:
import { getCSRF } from '../../actions/authentication';
import {
  participationConfirmationToggle,
  submitParticipation,
} from '../../actions/participation';
import ParticipateInformation from './ParticipateInformation';

const ParticipateConfirmation = ({
  csrfToken,
  information,
  selections,
  submitSuccess,
  toggleConfirmation,
}) => {
  const [modal, setModal] = useState(toggleConfirmation);
  const [sum, setSum] = useState(0);
  const toggle = useCallback(() => setModal((prevModal) => !prevModal), []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (toggleConfirmation) {
      toggle();
    }
  }, [toggleConfirmation, toggle]);

  useEffect(() => {
    if (submitSuccess) {
      toggle();
    }
  }, [submitSuccess, toggle]);

  useEffect(() => {
    if (!modal) {
      dispatch(participationConfirmationToggle(false));
    }
  }, [modal, dispatch]);

  useEffect(() => {
    let total = 0;
    selections.forEach((selection) => {
      total += selection.survey_price;
    });
    setSum(total);
  }, [selections]);

  const handleSubmit = (e) => {
    const body = {
      surveys: selections,
      participant: information,
    };
    e.preventDefault();
    const form = e.target;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      dispatch(submitParticipation(body, csrfToken));
    }
    form.classList.add('was-validated');
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      centered
      style={{ minWidth: '800px' }}
    >
      <ModalHeader toggle={toggle}>
        <span style={{ marginLeft: '15px' }}>
          2025 PARTICIPATION CONFIRMATION
        </span>
      </ModalHeader>
      <Form onSubmit={handleSubmit} noValidate>
        <ModalBody style={{ borderBottom: '1px solid #e9ecef' }}>
          <table id='participation-confirmation-table'>
            <tbody>
              {selections.map((selection) => (
                <tr key={selection.survey_guid}>
                  <td>{selection.survey_name}</td>
                  <td style={{ textAlign: 'center' }}>{selection.name}</td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 0,
                    }).format(selection.survey_price)}
                  </td>
                  {/* <td>
                  <i style={{ cursor: 'pointer' }} className='fa fa-times' />
                </td> */}
                </tr>
              ))}
              <tr>
                <td></td>
                <td style={{ textAlign: 'right' }}>
                  <strong>Total</strong>
                </td>
                <td>
                  <strong>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 0,
                    }).format(sum)}
                  </strong>
                </td>
                {/* <td></td> */}
              </tr>
            </tbody>
          </table>
        </ModalBody>
        <ModalBody>
          <ParticipateInformation />
        </ModalBody>
        <ModalFooter style={{ padding: '15px 30px' }}>
          <Button
            color='primary'
            // onClick={handleSubmit}
            style={{ width: '100%' }}
          >
            Confirm
          </Button>
          <Button color='secondary' onClick={toggle} style={{ width: '100%' }}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapDispatchToProps = {
  getCSRF,
};

const mapStateToProps = (state) => {
  return {
    csrfToken: state.auth.csrfToken,
    information: state.participation.information,
    selections: state.participation.selections,
    submitSuccess: state.participation.success,
    toggleConfirmation: state.participation.toggle,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipateConfirmation);
