import React, { Component } from 'react';
import { reduxForm, formValueSelector, Field } from 'redux-form';
import { connect } from 'react-redux';

import { getCSRF } from '../../actions/authentication';
import {
  checkUsername,
  checkPassword,
  setupUser,
  unpermitRender,
  verifyKey,
} from '../../actions/authentication';
import { clearMessages } from '../../actions/messages';
import { Col, Input, Row } from 'reactstrap';

import Loading from '../../components/Global/Loading';


class SetupUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
      touched: false,
      touched2: false,
      eightChar: false,
      upperCase: false,
      specChar: false,
      number: false,
      match: false,
      method: 'pwd',
      ndaRead: false,
      nda: false,
      popoverOpen: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleNda = this.handleNda.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  UNSAFE_componentWillMount() {
    // console.log(this.props.match.params);
    this.props.getCSRF();
    this.props.verifyKey(this.props.match.params);
  }

  handleChange(e) {
    // update after onChange() to render errors if true
    this.setState({ touched: true });

    // set password1 to state
    if (e.target.name === 'password1') {
      this.setState({ password1: e.target.value });
    }
    // set password2 to state
    if (e.target.name === 'password2') {
      this.setState({ password2: e.target.value });
    }

    // password1 length test
    if (e.target.name === 'password1') {
      if (e.target.value.length >= 8) {
        this.setState({ eightChar: true });
      } else {
        this.setState({ eightChar: false });
      }

      // password1 uppercase test
      if (/([A-Z]+)/g.test(e.target.value)) {
        this.setState({ upperCase: true });
      } else {
        this.setState({ upperCase: false });
      }

      // password1 special character test
      if (/([`~!@#$%^&*()_|+\-=?;:'',.<>\]\\]+)/g.test(e.target.value)) {
        this.setState({ specChar: true });
      } else {
        this.setState({ specChar: false });
      }

      // password1 has number
      if (/([0-9]+)/g.test(e.target.value)) {
        this.setState({ number: true });
      } else {
        this.setState({ number: false });
      }
    }

    // check if the passwords match
    if (e.target.name === 'password2') {
      this.setState({ touched2: true });
      if (e.target.value === this.state.password1) {
        this.setState({ match: true });
      } else {
        this.setState({ match: false });
      }
    }
  }

  handleNda() {
    const { nda } = this.state;

    this.setState({
      nda: !nda,
    });
  }

  handleScroll(e) {
    // const { ndaRead } = this.state;

    const atBottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (atBottom) {
      this.setState({
        ndaRead: true,
      });
    }
  }

  // Where the user input is sent over to the action to be validated by server
  onSubmit(values) {
    this.props.setupUser(values, this.state, this.props.match.params, this.props.csrfToken);
    this.props.clearMessages();
  }

  // This renders errors regarding the form inputs
  renderField(field) {
    const {
      placeholder,
      type,
      // name,
      disabled,
      meta: { touched, error },
    } = field;

    if (disabled) {
      return (
        <div className='form-group'>
          <input
            className='form-control'
            disabled
            type={type}
            placeholder={placeholder}
            {...field.input}
          />
          <div className='text-danger'>{touched ? error : ''}</div>
        </div>
      );
    } else {
      return (
        <div className='form-group'>
          <input
            className='form-control'
            type={type}
            placeholder={placeholder}
            {...field.input}
          />
          <div className='text-danger'>{touched ? error : ''}</div>
        </div>
      );
    }
  }

  renderSelectField(field) {
    const {
      label,
      meta: { touched, error },
    } = field;

    return (
      <div className='form-group'>
        <label>{label}</label>
        <select
          className='form-control'
          name={field.input.name}
          onChange={field.input.onChange}
        >
          <option>Select a question:</option>
          {field.questions}
        </select>
        <div className='text-danger'>{touched ? error : ''}</div>
      </div>
    );
  }

  toggle() {
    if (!this.state.ndaRead) {
      this.setState({
        popoverOpen: !this.state.popoverOpen,
      });
    }
  }

  getSecurityQuestionOptions() {
    const securityQuestions = [
      'To what city did you go the first time you flew on a plane?',
      'What is the first name of your best friend in high school?',
      'What is the first name of your oldest nephew?',
      'What is the first name of your oldest niece?',
      "What is your oldest cousin's first name?",
      'What was the last name of your third grade teacher?',
      'What was the street name where your best friend in high school lived (street name only)?',
      'How much did you pay for your first car?',
      'In what city or town was your first job?',
      'In which city did you first study abroad?',
      'What is the last name of your first boss?',
      'What is the name of the first beach you visited?',
      'What was the last name of your favorite childhood teacher?',
      'What was the last name of your favorite elementary school teacher?',
      'What was the last name of your favorite high school teacher?',
      'What was the name of the street where you were living when you were 10 years old?',
      'What was the name of the street where you were living when you were in third grade?',
      'What was your childhood nickname?',
      'What is the zip code where you grew up?',
      "What was the make and model of your best friend's car in high school?",
    ];
    return securityQuestions.map((securityQuestion) => (
      <option value={securityQuestion} key={securityQuestion}>
        {securityQuestion}
      </option>
    ));
  }

  validateUsername(values) {
    if (values.currentTarget.value) {
      this.props.checkUsername(values.currentTarget.value);
      this.props.clearMessages();
    }
  }

  // The main body to be rendered
  render() {

    const {
      valid,
      handleSubmit,
      initialValues,
      permitRender,
      username,
      a1,
      a2,
      firstname,
      lastname,
      errorMessage,
    } = this.props;

    const {
      eightChar,
      match,
      number,
      password1,
      password2,
      specChar,
      touched,
      touched2,
      upperCase,
      nda,
      // ndaRead
    } = this.state;

    if (permitRender) {
      return (
        <div id='account-setup'>
          <div id='title'>
            <h1>Account Setup</h1>
          </div>
          <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <div id='account-setup-content'>
              <Row>
                <Col lg='5'>
                  <h4>Set Username</h4>
                  <hr />
                  <p>
                    Please enter the username you would like associated with
                    your account. It must be at least three characters in
                    length.{' '}
                  </p>
                </Col>
                <Col lg='7'>
                  <Field
                    name='username'
                    type='text'
                    // disabled={initialValues.username ? true : false}
                    placeholder={
                      initialValues.username
                        ? initialValues.username
                        : 'Enter Username'
                    }
                    onBlur={this.validateUsername.bind(this)}
                    component={this.renderField}
                  />
                </Col>
              </Row>
            </div>
            <hr style={{ margin: '0 80px' }} />
            <div id='account-setup-content'>
              <Row>
                <Col lg='5'>
                  <h4>Set Password</h4>
                  <hr />
                  <div id='password-criteria'>
                    <p>The new password must meet the following criteria:</p>
                    <ul style={{ marginBottom: '16px' }}>
                      <li
                        className={
                          touched && eightChar
                            ? 'success'
                            : touched && !eightChar
                            ? 'error'
                            : 'neutral'
                        }
                      >
                        <i
                          className={
                            touched && eightChar
                              ? 'fas fa-check'
                              : touched && !eightChar
                              ? 'fas fa-times'
                              : 'fas fa-minus'
                          }
                        ></i>{' '}
                        At least eight characters in length
                      </li>
                      <li
                        className={
                          touched && upperCase
                            ? 'success'
                            : touched && !upperCase
                            ? 'error'
                            : 'neutral'
                        }
                      >
                        <i
                          className={
                            touched && upperCase
                              ? 'fas fa-check'
                              : touched && !upperCase
                              ? 'fas fa-times'
                              : 'fas fa-minus'
                          }
                        ></i>{' '}
                        One uppercase character
                      </li>
                      <li
                        className={
                          touched && specChar
                            ? 'success'
                            : touched && !specChar
                            ? 'error'
                            : 'neutral'
                        }
                      >
                        <i
                          className={
                            touched && specChar
                              ? 'fas fa-check'
                              : touched && !specChar
                              ? 'fas fa-times'
                              : 'fas fa-minus'
                          }
                        ></i>{' '}
                        One special character
                      </li>
                      <li
                        className={
                          touched && number
                            ? 'success'
                            : touched && !number
                            ? 'error'
                            : 'neutral'
                        }
                      >
                        <i
                          className={
                            touched && number
                              ? 'fas fa-check'
                              : touched && !number
                              ? 'fas fa-times'
                              : 'fas fa-minus'
                          }
                        ></i>{' '}
                        One number
                      </li>
                      <li
                        className={
                          touched2 && match
                            ? 'success'
                            : touched2 && !match
                            ? 'error'
                            : 'neutral'
                        }
                      >
                        <i
                          className={
                            touched2 && match
                              ? 'fas fa-check'
                              : touched2 && !match
                              ? 'fas fa-times'
                              : 'fas fa-minus'
                          }
                        ></i>{' '}
                        Passwords match
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg='7'>
                  <Input
                    value={this.state.password1}
                    onChange={this.handleChange}
                    type='password'
                    name='password1'
                    placeholder='Enter New Password'
                  />
                  <Input
                    disabled={
                      eightChar && number && specChar && upperCase
                        ? false
                        : true
                    }
                    value={this.state.password2}
                    onChange={this.handleChange}
                    type='password'
                    name='password2'
                    placeholder='Re-enter New Password'
                  />
                </Col>
              </Row>
            </div>
            <hr style={{ margin: '0 80px' }} />
            <div id='account-setup-content'>
              <Row>
                <Col lg='5'>
                  <h4>Set Security Questions</h4>
                  <hr />
                  <p>
                    Please select two security questions that will be easy for
                    you to remember.
                  </p>
                </Col>
                <Col lg='7'>
                  <Field
                    name='q1'
                    label='Question 1'
                    type='select'
                    disabled={false}
                    questions={this.getSecurityQuestionOptions()}
                    component={this.renderSelectField}
                  />

                  <Field
                    placeholder='Answer 1'
                    name='a1'
                    type='text'
                    disabled={false}
                    component={this.renderField}
                  />

                  <br />

                  <Field
                    name='q2'
                    label='Question 2'
                    type='select'
                    disabled={false}
                    questions={this.getSecurityQuestionOptions()}
                    component={this.renderSelectField}
                  />

                  <Field
                    placeholder='Answer 2'
                    name='a2'
                    type='text'
                    disabled={false}
                    component={this.renderField}
                  />
                </Col>
              </Row>
            </div>
            <hr style={{ margin: '0 80px' }} />
            <div id='account-setup-content'>
              <Row>
                <Col lg='5'>
                  <h4>Personal Information</h4>
                  <hr />
                  <p>
                    <em>
                      These fields are not editable. Please confirm this
                      information and notify The Croner Company if anything is
                      incorrect.
                    </em>
                  </p>
                </Col>
                <Col lg='7'>
                  <Field
                    name='company'
                    type='text'
                    disabled={true}
                    placeholder={initialValues.company}
                    component={this.renderField}
                  />

                  <Field
                    name='firstname'
                    type='text'
                    disabled={initialValues.firstname ? true : false}
                    placeholder={
                      initialValues.firstname
                        ? initialValues.firstname
                        : 'Enter First Name'
                    }
                    component={this.renderField}
                  />

                  <Field
                    name='lastname'
                    type='text'
                    disabled={initialValues.lastname ? true : false}
                    placeholder={
                      initialValues.lastname
                        ? initialValues.lastname
                        : 'Enter Last Name'
                    }
                    component={this.renderField}
                  />

                  <Field
                    name='email'
                    type='text'
                    disabled={true}
                    placeholder={initialValues.email}
                    component={this.renderField}
                  />
                </Col>
              </Row>
            </div>
            <hr style={{ margin: '0 80px' }} />
            <div id='account-setup-content'>
              <Row>
                <Col lg='12'>
                  <div id='nda'>
                    <p style={{ textAlign: 'center', marginBottom: '0px' }}>
                      <strong>
                        CRONER SURVEY SECURE WEB PORTAL USER AGREEMENT
                      </strong>
                    </p>
                    <hr
                      style={{
                        marginTop: '0',
                        marginLeft: '15px',
                        marginRight: '15px',
                      }}
                    />
                    <p>
                      By acknowledging this{' '}
                      <em>Croner Survey Secure Web Portal User Agreement</em>,
                      you (the Customer registering to use this service) agree
                      to abide by the following rights and restrictions of use.
                    </p>
                    <p style={{ textAlign: 'center' }}>
                      <strong>DEFINITIONS</strong>
                    </p>
                    <ul style={{ paddingLeft: '30px' }}>
                      <li>
                        <p>
                          "Customer" is a Croner Survey Secure Web Portal site
                          user who has been granted credentialed access to this
                          site by The Croner Company.
                        </p>
                      </li>
                      <li>
                        <p>
                          "Company" is the specific company, foundation or other
                          organization that has designated this Customer as a
                          site user, in accordance with that specific entity's
                          agreement with The Croner Company to participate in a
                          given Survey and/or receive Survey results.
                        </p>
                      </li>
                      <li>
                        <p>"Croner" is defined as The Croner Company.</p>
                      </li>
                      <li>
                        <p>
                          "Site" is the<em> Croner Survey Secure Web Portal</em>
                          , including all content, functionality, design
                          elements and code.
                        </p>
                      </li>
                    </ul>
                    <p style={{ textAlign: 'center' }}>
                      <strong>
                        INTELLECTUAL PROPERTY RIGHTS&nbsp;AND RESTRICTIONS
                      </strong>
                    </p>
                    <ol style={{ paddingLeft: '30px' }}>
                      <li>
                        <p>
                          <span style={{ textDecoration: 'underline' }}>
                            <strong>Online Service</strong>
                          </span>
                          .<strong> &nbsp;</strong>Croner grants to the Customer
                          a non-exclusive, non-transferable, non-assignable
                          personal license (without the right to sub-license) to
                          use this Site. &nbsp;The Site shall not be accessed,
                          used or distributed to any additional persons without
                          express, written approval from Croner.&nbsp; Likewise,
                          the contents of this Site (and/or any elements
                          thereof) shall not be copied, displayed, distributed,
                          resold, transferred or otherwise provided to any other
                          individuals or third parties, other than within the
                          Customer's Company, and only as permitted by the
                          Company's agreement with Croner regarding
                          participation in one or more Surveys. &nbsp;This
                          Online Service (or any elements thereof) shall not be
                          disclosed, de-compiled, disassembled or otherwise
                          reverse engineered by the Customer or any other
                          representative of the Company.
                        </p>
                      </li>
                      <li>
                        <p>
                          <span style={{ textDecoration: 'underline' }}>
                            <strong>Materials and Methodology</strong>
                          </span>
                          .&nbsp; Upon the acknowledgement of this{' '}
                          <em>Agreement</em> by the Customer, Croner grants to
                          the Company a limited, non-exclusive, non-transferable
                          license (without the right to sub-license) to use
                          Survey Materials made available to the Customer via
                          this Site, including but not limited to
                          questionnaires,reports, data, special analyses and
                          results, in the form and format provided by Croner,
                          and solely for use by the Customer's Company, but not
                          otherwise. &nbsp;Croner shall retain any and all of
                          its intellectual property rights embodied in the Site
                          as well as in Survey Materials.
                        </p>
                        <p>
                          All rights, title and interest in and to Croner
                          property hereunder are and shall remain the property
                          of Croner, and the Customer shall not dispute or
                          contest ownership of the foregoing. &nbsp;Croner
                          reserves all rights in and to Croner property not
                          expressly granted to Customer. This agreement does not
                          grant Customer nor the Customer's Company any rights
                          or implied licenses other than those expressly granted
                          herein.
                        </p>
                      </li>
                      <li>
                        <p>
                          <span style={{ textDecoration: 'underline' }}>
                            <strong>Indemnity</strong>
                          </span>
                          <strong>.&nbsp; </strong>The Customer agrees to
                          defend, indemnify and hold harmless Croner, its
                          officers, directors, employees and agents, from and
                          against any and all claims, damages, obligations,
                          losses, liabilities, costs or debt, and expenses
                          (including but not limited to attorney's fees) arising
                          from: (i) your use of and access to Croner's Site and
                          its Survey Materials; (ii) your violation of any term
                          of these conditions of use; (iii) your violation of
                          any third-party right, including without limitation
                          any copyright, property or privacy right; or (iv) any
                          claim that your content caused damage to a third
                          party. This defense and indemnification obligation
                          will survive these conditions of use and the
                          Customer&rsquo;s use of Croner's Site and services.
                        </p>
                      </li>
                      <li>
                        <p>
                          <span style={{ textDecoration: 'underline' }}>
                            <strong>Force Majeure</strong>
                          </span>
                          <strong>.&nbsp; </strong>Croner&nbsp;shall not be
                          liable under any circumstances for any delay,
                          disruption or failure of the Customer's Company
                          directly or indirectly resulting from events beyond
                          the control of&nbsp;Croner.
                        </p>
                      </li>
                    </ol>
                    <p style={{ textAlign: 'center' }}>
                      <strong>CONFIDENTIALITY AND NONDISCLOSURE</strong>
                    </p>
                    <ol style={{ paddingLeft: '30px' }} start='5'>
                      <li>
                        <p>
                          <span style={{ textDecoration: 'underline' }}>
                            <strong>Confidential Information Defined</strong>
                          </span>
                          <strong>.&nbsp; </strong>As used in this{' '}
                          <em>Agreement</em>, "Confidential Information" means
                          any information: (i) having independent economic value
                          that is owned or lawfully controlled by Croner and/or
                          the Customer's Company; (ii) is not generally known to
                          the public; (iii) is of a technical, business or other
                          proprietary nature (including, without limitation,
                          trade secrets, know-how, customer lists, business
                          plans, compensation and financial information); or
                          (iv) is identified by the party disclosing the
                          information (whether Croner or the Customer's Company)
                          to the other party as being proprietary and/or
                          confidential or which, by the nature of the
                          circumstances surrounding the disclosure, the
                          receiving party should reasonably know is proprietary
                          and confidential.&nbsp;&nbsp;
                        </p>
                        <p>
                          Confidential Information does not include any
                          information that: (i) is or becomes publicly available
                          for any reason other than a breach of this{' '}
                          <em>Agreement</em>; (ii) was known to both parties at
                          the time of its disclosure; (iii) is rightfully
                          received from a third party who did not acquire or
                          disclose such information by a wrongful or tortious
                          act; or (iv) is independently developed by one party
                          without reference to any Confidential Information
                          belonging to the other party.
                        </p>
                        <p>
                          "Survey Materials" , includes, but is not limited to,
                          Croner's Survey questionnaires,reports, data, special
                          analyses and results, and any other files, documents
                          or information related to Croner Surveys and
                          services.&nbsp; Croner's Survey Materials are
                          considered "Confidential Information" for the purposes
                          of this <em>Agreement</em>.
                        </p>
                      </li>
                      <li>
                        <p>
                          <span style={{ textDecoration: 'underline' }}>
                            <strong>
                              Agreement Not to Disclose or Use Confidential
                              Information
                            </strong>
                          </span>
                          <strong>.&nbsp; </strong>The Customer, except as
                          expressly provided in this <em>Agreement</em> but
                          subject to the limitations stated herein, agrees on
                          behalf of the Company not to disclose any Survey
                          Materials to any third party without Croner's prior
                          written consent.&nbsp; Both the Customer and Croner
                          will take all reasonable measures to protect each
                          other's' Confidential Information against disclosure,
                          including, at a minimum, using the same precautions
                          each party takes to protect its own Confidential
                          Information, but with no less than reasonable
                          care.&nbsp; &nbsp;
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span style={{ textDecoration: 'underline' }}>
                              <span style={{ textDecorationLine: 'underline' }}>
                                Disclosure to Governmental Entities
                              </span>
                            </span>
                            .&nbsp;{' '}
                          </strong>
                          If either the Customer or Croner becomes legally
                          obligated to disclose any of the other party's
                          Confidential Information pursuant to an order or
                          similar compulsion from any governmental entity having
                          jurisdiction over it, the Customer or Croner agree to
                          give each other prompt written notice to that effect,
                          and each agrees to cooperate with any efforts to seek
                          a protective order or other appropriate
                          remedy.&nbsp;&nbsp;
                        </p>
                        <p>
                          The Customer agrees on behalf of the Company to
                          defend, indemnify and hold harmless Croner, its
                          officers, directors, employees and agents from and
                          against any and all claims, damages, obligations,
                          losses, liabilities, costs, debt and expenses
                          (including but not limited to attorney's fees) arising
                          from any and all liability and expenses (including
                          reimbursement of reasonable attorneys' fees and other
                          costs of suit) incurred by Croner in connection with
                          any such cooperation with respect to such actions by
                          the Customer or the Company.&nbsp;
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span style={{ textDecoration: 'underline' }}>
                              <span style={{ textDecorationLine: 'underline' }}>
                                Ownership of Confidential Information
                              </span>
                            </span>
                            .&nbsp;{' '}
                          </strong>
                          The Company retains all ownership and control of its
                          Confidential Information, and nothing in this{' '}
                          <em>Agreement</em> will be construed to grant to
                          Croner any ownership interest or rights to use the
                          Company's Confidential Information except as expressly
                          provided in this <em>Agreement</em>.&nbsp;&nbsp;
                        </p>
                        <p>
                          Likewise, Croner retains all ownership and control of
                          its Confidential Information, including its Survey
                          Materials, and nothing in this <em>Agreement</em> will
                          be construed to grant to the Customer or the Company
                          any ownership interest or rights to use the Croner's
                          Confidential Information, except as expressly provided
                          in this <em>Agreement</em>.&nbsp;
                        </p>
                      </li>
                      <li>
                        <p>
                          <span style={{ textDecoration: 'underline' }}>
                            <strong>Independent Development</strong>
                          </span>
                          <strong>.&nbsp; </strong>The Customer acknowledges
                          that Croner may develop information internally or
                          receive information from other parties that is similar
                          to the Company's Confidential Information.&nbsp;
                          Therefore, nothing in this <em>Agreement</em> will be
                          construed as a representation or agreement that Croner
                          has not or will not develop or otherwise rightfully
                          receive products, concepts, systems, techniques etc.
                          that are similar to or compete with the proprietary
                          material embodied in the Confidential Information, so
                          long as Croner does not violate any of its obligations
                          under this <em>Agreement</em>.
                        </p>
                      </li>
                    </ol>
                    <p style={{ textAlign: 'center' }}>
                      <strong>GENERAL AGREEMENT TERMS</strong>
                    </p>
                    <ol style={{ paddingLeft: '30px' }} start='10'>
                      <li>
                        <p>
                          <strong>
                            <span style={{ textDecoration: 'underline' }}>
                              <span style={{ textDecorationLine: 'underline' }}>
                                Scope and Duration
                              </span>
                            </span>
                            .&nbsp;{' '}
                          </strong>
                          This <em>Agreement</em> covers any and all
                          Confidential Information disclosed by the Company to
                          Croner, and also covers the Intellectual Property
                          rights of Croner pertaining to the Site and
                          Croner&rsquo;s Survey Materials as described
                          above.&nbsp; This <em>Agreement</em> will survive the
                          termination or cessation of any negotiations between
                          the parties and will remain in effect for as long as
                          the Confidential Information disclosed by either party
                          has independent economic value, but in no case longer
                          than 12 months after the date hereof.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span style={{ textDecoration: 'underline' }}>
                              <span style={{ textDecorationLine: 'underline' }}>
                                Representations, Warranties and Indemnities
                              </span>
                            </span>
                            .&nbsp;{' '}
                          </strong>
                          The Customerrepresents and warrants to Croner that the
                          Customer's Company has all rights in and to the
                          Confidential Information necessary to allow its
                          disclosure to and use by Croner as authorized by this{' '}
                          <em>Agreement</em>.&nbsp;&nbsp;
                        </p>
                        <p>
                          Likewise, the Customerrepresents and warrants to
                          Croner that the Customer is authorized to acknowledge
                          and agree to this <em>Agreement</em> on behalf of the
                          Company.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span style={{ textDecoration: 'underline' }}>
                              <span style={{ textDecorationLine: 'underline' }}>
                                Injunctive Relief
                              </span>
                            </span>
                            .&nbsp;{' '}
                          </strong>
                          The Customer acknowledges that disclosure by the
                          Customer, or by any representative of the Company, of
                          Croner's Confidential Information in violation of this{' '}
                          <em>Agreement</em> could cause irreparable harm to
                          Croner for which monetary damages may be difficult to
                          ascertain or otherwise be an inadequate remedy.&nbsp;
                          Therefore, in addition to its other rights and
                          remedies, Croner retains the right to seek injunctive
                          relief for any violation of this <em>Agreement</em>.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span style={{ textDecoration: 'underline' }}>
                              <span style={{ textDecorationLine: 'underline' }}>
                                Limited Relationship
                              </span>
                            </span>
                            .&nbsp;{' '}
                          </strong>
                          This <em>Agreement</em> does not create a joint
                          venture, partnership or other formal business
                          relationship or entity of any kind, nor does it create
                          an obligation to form any such relationship or entity
                          between Croner and the Customer or the
                          Company.&nbsp;&nbsp;
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span style={{ textDecoration: 'underline' }}>
                              <span style={{ textDecorationLine: 'underline' }}>
                                Entire Agreement and Amendment
                              </span>
                            </span>
                            .&nbsp;{' '}
                          </strong>
                          This <em>Agreement</em> constitutes the entire
                          agreement between the parties relating to the matters
                          stated herein and may be amended or modified only with
                          the mutual written consent of the parties.&nbsp;
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span style={{ textDecoration: 'underline' }}>
                              <span style={{ textDecorationLine: 'underline' }}>
                                Governing Law and Forum
                              </span>
                            </span>
                            .&nbsp;{' '}
                          </strong>
                          This <em>Agreement</em> is governed by the laws of the
                          State of California, without reference to its choice
                          of law rules.&nbsp; The Customer consents to
                          designating the State of California as the exclusive
                          jurisdiction and venue for resolving any dispute
                          related to this <em>Agreement</em>.
                        </p>
                      </li>
                    </ol>
                  </div>

                  <div id='nda-read' className='nda-confirm'>
                    <label>
                      <Input type='checkbox' onClick={this.handleNda} />{' '}
                      {
                        'By selecting this box, I agree to the Non-disclosure Agreement.'
                      }
                    </label>
                  </div>
                  <button
                    style={{ width: '100%' }}
                    disabled={
                      !errorMessage &&
                      valid &&
                      eightChar &&
                      match &&
                      number &&
                      password1 &&
                      password2 &&
                      specChar &&
                      username &&
                      a1 &&
                      a2 &&
                      firstname &&
                      lastname &&
                      nda
                        ? false
                        : true
                    }
                    type='submit'
                    className='blue-button'
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            </div>
          </form>
        </div>
      );
    } else if (!permitRender) {
      return <Loading />;
    }
  }
}

// General form validation to make sure fields have entries
function validate(values) {
  const errors = {};

  // Username validation
  if (!values.username) {
    errors.username = 'This field cannot be empty.';
  } else if (values.username.length < 3) {
    errors.username = 'Must be at least 3 characters in length.';
  }

  // Password1 validation
  if (!values.password1) {
    errors.password1 = 'This field cannot be empty.';
  } else if (values.password1.length < 8) {
    errors.password1 = 'The password must be eight characters in length.';
  } else if (!/([A-Z]+)/g.test(values.password1)) {
    errors.password1 = 'There must be at least one uppercase character.';
  } else if (!/([0-9]+)/g.test(values.password1)) {
    errors.password1 = 'There must be at least one number.';
  } else if (!/([`~!@#$%^&*()_|+\-=?;:'',.<>\]\\]+)/g.test(values.password1)) {
    errors.password1 = 'There must be at least one special character.';
  }

  // Password2 validation
  if (!values.password2) {
    errors.password2 = 'This field cannot be empty.';
  } else if (values.password2.length < 8) {
    errors.password2 = 'The password must be eight characters in length.';
  } else if (!/([A-Z]+)/g.test(values.password2)) {
    errors.password2 = 'There must be at least one uppercase character.';
  } else if (!/([0-9]+)/g.test(values.password2)) {
    errors.password2 = 'There must be at least one number.';
  } else if (!/([`~!@#$%^&*()_|+\-=?;:'',.<>\]\\]+)/g.test(values.password2)) {
    errors.password2 = 'There must be at least one special character.';
  } else if (values.password1 !== values.password2) {
    errors.password2 = 'The passwords do not match.';
  }

  // Specialty question validation
  if (values.q1 === 'Select a question:') {
    errors.q1 = 'Invalid question selection.';
  }
  if (values.q2 === 'Select a question:') {
    errors.q2 = 'Invalid question selection.';
  }
  if (values.q2 === values.q1) {
    errors.q1 = 'Questions cannot be the same.';
    errors.q2 = 'Questions cannot be the same.';
  }
  if (!values.a1) {
    errors.a1 = 'This field cannot be empty.';
  }
  if (!values.a2) {
    errors.a2 = 'This field cannot be empty.';
  }

  // First name validation
  if (!values.firstname) {
    errors.firstname = 'This field cannot be empty.';
  }

  // Last name validation
  if (!values.lastname) {
    errors.lastname = 'This field cannot be empty.';
  }

  return errors;
}

// This is where the potential error state that was created by invalid credentials
// is turned into a prop so it can be rendered
function mapStateToProps(state) {
  return {
    permitRender: state.auth.permitRender,
    initialValues: state.auth.acctVal,
    errorMessage: state.messages.errors,
    csrfToken: state.auth.csrfToken,
  };
}

SetupUser = reduxForm({
  form: 'setup_user',
  touchOnChange: true,
  validate,
})(SetupUser);

const selector = formValueSelector('setup_user');

SetupUser = connect((state) => {
  const username = selector(state, 'username');
  const a1 = selector(state, 'a1');
  const a2 = selector(state, 'a2');
  const firstname = selector(state, 'firstname');
  const lastname = selector(state, 'lastname');
  return {
    username,
    a1,
    a2,
    firstname,
    lastname,
  };
})(SetupUser);

SetupUser = connect(mapStateToProps, {
  checkUsername,
  checkPassword,
  clearMessages,
  setupUser,
  unpermitRender,
  verifyKey,
  getCSRF
})(SetupUser);
export default SetupUser;
