import _ from 'lodash';
import axios from 'axios';
import { saveAs } from 'file-saver';

// import { URL } from "../index";

// Establish the different types
export const DOC_DOWNLOAD_ERROR = 'doc_download_error';
export const DOC_UPLOAD_ERROR = 'doc_upload_error';
export const DOCUMENTS = 'documents';
export const DOWNLOAD_STATUS = 'download_status';
export const UPLOAD_RESPONSE = 'upload_response';

// Clear out the store when the navigates away from /documents
export function clearDocuments() {
  return function (dispatch) {
    dispatch({
      type: DOCUMENTS,
      payload: '',
    });
  };
}

// Clear stored server status after documents have been uploaded
export function clearStatus() {
  return function (dispatch) {
    dispatch({
      type: UPLOAD_RESPONSE,
      payload: undefined,
    });
  };
}

// download documents
export function downloadDocDocs(id, doc) {
  return function (dispatch) {
    dispatch({
      type: DOWNLOAD_STATUS,
      payload: true,
    });
    // send over the get request with the doc id
    // make sure the responseType is 'blob' to receive files
    axios
      .get(`/api/client/survey/${id}/documents/${doc}/`, {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
      })
      // get the filename from the response headers
      // and then download the file
      .then((response) => {
        let filename = '';
        let disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        let blob = new Blob([response.data], { type: response.data.type });

        saveAs(blob, filename);

        dispatch({
          type: DOWNLOAD_STATUS,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: DOC_DOWNLOAD_ERROR,
          payload: {
            error: [
              'There was an issue downloading the file. ' +
                'The Croner Company has been notified and is investigating the issue. ' +
                'You will be contacted shortly.',
            ],
          },
        });
      });
  };
}

// This just gets the documents for the current survey
export function getDocuments(id) {
  return function (dispatch) {
    axios
      .get(`/api/client/survey/${id}/documents/`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        dispatch({
          type: DOCUMENTS,
          payload: response.data,
        });
        sessionStorage.setItem('survey_id', response.data.survey_guid);
      });
  };
}

export function submitDocuments(accepetedFiles, rejectedFiles, id, csrfToken) {
  return function (dispatch) {
    var formData = new FormData();

    // handle the accepetedfile and append to formData
    _.map(accepetedFiles, (f) => {
      formData.append('file', f);
    });
    // handle the rejectedfiles and append to formData
    _.map(rejectedFiles, (r) => {
      formData.append('rejected', r);
    });

    // Send the formDat to the server
    axios
      .post(`/api/client/survey/${id}/documents/upload/`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
          credentials: 'same-orgin',
          'X-CSRFToken': csrfToken,
        },
      })
      // handling of the server response, should enable the modal to render
      .then((response) => {
        dispatch({
          type: UPLOAD_RESPONSE,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: DOC_UPLOAD_ERROR,
          payload: { error: [error.response.data.err] },
        });
      });
  };
}
